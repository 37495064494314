import React, { useState, useEffect } from "react";
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { GameControllerOutline, CashOutline, TrophyOutline } from 'react-ionicons';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CS2NoBackground from "../cashoutCup/cs2NoBackground.png";

import CheckoutFormSS from './checkoutFormSS';
import "./saturdayShowdown.css";

// Initialize Stripe outside of the component to avoid re-creating on each render
const stripePromise = loadStripe('pk_live_51PBydeIitOy6POD7I5OZ0HRyiem5lj1BeDsnqdVuiKGlLCZZeKhOocuYL8M9ErDpXzWCd9m1hUxPpt8jdhVQQnCo00d7OYIqkH');

// Register required elements for Chart.js and the datalabels plugin
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const SaturdayShowdownSS = () => {
  const [formData, setFormData] = useState({
    teamName: '',
    captainName: '',
    teamLink: '',
  });
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const ResponsiveIcon = ({ IconComponent, defaultMargin, mobileMargin, color }) => {
    const [marginLeft, setMarginLeft] = useState(defaultMargin);
  
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <= 768) {
          setMarginLeft(mobileMargin);
        } else {
          setMarginLeft(defaultMargin);
        }
      };
  
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, [defaultMargin, mobileMargin]);
  
    return (
      <IconComponent color={color} height="150px" width="150px" style={{ marginLeft }} />
    );
  };

  return (
    <Elements stripe={stripePromise}>
      <div>
        <div className="cashout-cup-header">
          <div className="cashout-cup-header-text-holder">
            <h1 className="cashout-cup-header-h1">Saturday Showdown</h1>
            <h2 className="cashout-cup-header-h2">Every Saturday</h2>
          </div>
          <div className="cashout-cup-header-image-holder">
            <img src={CS2NoBackground} alt="Cashout Cup Logo" className="cashout-cup-header-image" />
          </div>
        </div>

        <div className="how-it-works-holder">
          <h2 className="how-it-works-h2">How it works</h2>
          <h3 className="how-it-works-h3" >This is a single elimination tournament, finals and semifinals are BO3, all other matches are BO1.</h3>
          <div className="how-it-works-content-holder">
            <div className="how-it-works-individual-holder">
              <ResponsiveIcon IconComponent={TrophyOutline} color={'#FFD700'}  />
              <h3 className="how-it-works-h3">1000NOK</h3>
            </div>
            <div className="how-it-works-individual-holder">
              <ResponsiveIcon IconComponent={TrophyOutline} color={'#C0C0C0'}  />
              <h3 className="how-it-works-h3">500NOK</h3>
            </div>
            <div className="how-it-works-individual-holder">
              <ResponsiveIcon IconComponent={TrophyOutline} color={'#CD7F32'}  />
              <h3 className="how-it-works-h3">1 Free entry</h3>
            </div>
          </div>
        </div>

        <div className="participation-requirements-holder">
          <h2 className="participation-requirements-h2">Participation Requirements</h2>
          <div className="participation-requirements-content-holder">
            <div className="participation-requirements-individual-holder">
              <h3 className="requirements-h3">Gamer.no</h3>
              <p className="requirements-p">Account required</p>
            </div>
            <div className="participation-requirements-individual-holder">
              <h3 className="requirements-h3">16+</h3>
              <p className="requirements-p">Required age</p>
            </div>
            <div className="participation-requirements-individual-holder">
              <h3 className="requirements-h3">3 out of 5</h3>
              <p className="requirements-p">Players must be Norwegian Citizens</p>
            </div>
          </div>
        </div>

        <div className="points-system-holder-ss">
          <div className="signup-container">
            {paymentSuccess ? (
              <div className="payment-success">
                <h2 className="successful">Signup Successful!</h2>
                <p>Thank you for signing up your team: <strong>{formData.teamName}</strong>.</p>
                <p>Captain: <strong>{formData.captainName}</strong></p>
                <p>Team Link: <a href={formData.teamLink} target="_blank" rel="noopener noreferrer">{formData.teamLink}</a></p>
              </div>
            ) : (
              <CheckoutFormSS
                formData={formData}
                setFormData={setFormData}
                setError={setError}
                setProcessing={setProcessing}
                setPaymentSuccess={setPaymentSuccess}
              />
            )}
          </div>
        </div>
      </div>
    </Elements>
  );
}

export default SaturdayShowdownSS;