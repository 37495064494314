import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import './checkoutForm.css';

const CheckoutForm = ({ setPaymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentStatus, setPaymentStatus] = useState('');
  const [formData, setFormData] = useState({
    teamName: '',
    captainName: '',
    teamLink: '',
  });
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    setError(null);
    setPaymentStatus('');


    if (!stripe || !elements) {
      setError("Stripe has not loaded yet. Please try again.");
      setProcessing(false);
      return;
    }

    try {
      console.log('Creating payment intent...');
      const response = await fetch('https://cashoutcavaliersbackend-9954216257f6.herokuapp.com/paymentRoutes/createPaymentIntentCashoutCup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          amount: 25000,
          currency: 'nok',
          metadata: {
            teamName: formData.teamName,
            captainName: formData.captainName,
            teamLink: formData.teamLink,
          },
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create payment intent. Server responded with ' + response.status);
      }

      const { clientSecret } = await response.json();
      console.log('Payment intent created successfully');
      setPaymentStatus('Payment has been successful, thank you for signing up with Cashout Cup!');

      console.log('Confirming card payment...');
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: { name: formData.captainName },
        },
      });

      console.log('Stripe confirmCardPayment result:', result);

      if (result.error) {
        throw new Error(result.error.message);
      } else if (result.paymentIntent.status === 'succeeded') {
        console.log('Payment succeeded, saving participant data...');
        const saveResponse = await fetch('https://cashoutcavaliersbackend-9954216257f6.herokuapp.com/paymentRoutes/addParticipants', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData),
        });

        if (!saveResponse.ok) {
          throw new Error('Payment succeeded, but failed to save participant data.');
        }

        console.log('Participant saved to database');
        setPaymentSuccess(true);
      }
    } catch (err) {
      console.error("Error during payment process:", err);
      setError(err.message || "An unexpected error occurred. Please try again.");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="cashout-cup-checkout-form">
      <h2 className='cashout-cup-checkout-title'>Sign up now to Cashout Cup #1!</h2>

      <div className="form-group">
        <label className='cashout-cup-checkout-label' htmlFor="teamName">Team Name</label>
        <input
          id="teamName"
          className='cashout-cup-checkout-input'
          type="text"
          name="teamName"
          value={formData.teamName}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <label className='cashout-cup-checkout-label' htmlFor="captainName">Captain Name</label>
        <input
          id="captainName"
          className='cashout-cup-checkout-input'
          type="text"
          name="captainName"
          value={formData.captainName}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <label className='cashout-cup-checkout-label' htmlFor="teamLink">Gamer.no Team Link</label>
        <input
          id="teamLink"
          className='cashout-cup-checkout-input'
          type="url"
          name="teamLink"
          value={formData.teamLink}
          onChange={handleChange}
          required
          placeholder="https://gamer.no/team-link"
        />
      </div>

      <h3 className='cashout-cup-checkout-label'>Payment (250 NOK)</h3>
      <div className="card-element-container">
        <CardElement options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#000',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#fa755a',
            },
          },
        }}/>
      </div>

      {error && <div className="error">{error}</div>}
      {paymentStatus && <div className="success">{paymentStatus}</div>}
      <button className="cashout-cup-checkout-button" type="submit" disabled={!stripe || processing}>
        {processing ? 'Processing...' : 'Submit Registration'}
      </button>
    </form>
  );
};

export default CheckoutForm;